<template>
  <v-dialog
    v-model="dialog"
    :width="width"
    :persistent="!closeable"
    transition="scroll-x-transition"
    scrollable
    :fullscreen="fullscreen"
  >
    <!-- is there a slot -->
    <slot v-if="$slots.card" name="card"></slot>
    <v-card
      v-else-if="$slots.message"
      :class="{ 'overflow-y-hidden': !noOverFlow, 'py-3': !noPy }"
      :disabled="loading"
      :height="height || 'auto'"
    >
      <v-card-title>
        <slot name="title"></slot>
      </v-card-title>
      <v-card-text :class="{ 'pt-5': fullscreen, 'py-0': noPy }">
        <slot name="message"></slot>
      </v-card-text>
      <v-card-actions>
        <slot name="actions"></slot>
      </v-card-actions>
      <v-btn
        v-if="!hideClose"
        @click="(dialog = false), $emit('close')"
        style="z-index: 1"
        :disabled="loading"
        icon
        absolute
        top
        right
        ><v-icon>close</v-icon>
      </v-btn>
    </v-card>
    <!-- if there is no slot, because this components has the non slot version and slot version -->
  </v-dialog>
</template>

<script>
export default {
  name: "Modal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: 500,
    },
    height: {
      type: [Number, String],
      default: "auto",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    closeable: {
      type: Boolean,
      default: false
    },
    cancelText: String,
    confirmText: String,
    message: String,
    title: String,
    onConfirm: Function,
    onCancel: Function,
    noOverFlow: Boolean,
    sensitiveAction: Boolean,
    fullscreen: Boolean,
    hideClose: Boolean,
    noPy: Boolean,
  },
  data() {
    return {
      dialog: null,
    };
  },

  watch: {
    dialog(v) {
      this.$emit("input", v);
    },
    value(v) {
      this.dialog = v;
    },
  },
  methods: {
    Cancel() {
      this.$emit("onCancel");
      this.dialog = false;
    },
    Confirm() {
      this.$emit("onConfirm");
    },
  },
  beforeMount() {
    this.$nextTick(() => {
      this.dialog = this.value;
    });
  },
};
</script>

<style lang="scss" scoped></style>
<style lang="scss">
.overflow-visible {
  overflow: visible !important;
}
</style>
