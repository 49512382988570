var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": _vm.width,
      "persistent": !_vm.closeable,
      "transition": "scroll-x-transition",
      "scrollable": "",
      "fullscreen": _vm.fullscreen
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_vm.$slots.card ? _vm._t("card") : _vm.$slots.message ? _c('v-card', {
    class: {
      'overflow-y-hidden': !_vm.noOverFlow,
      'py-3': !_vm.noPy
    },
    attrs: {
      "disabled": _vm.loading,
      "height": _vm.height || 'auto'
    }
  }, [_c('v-card-title', [_vm._t("title")], 2), _c('v-card-text', {
    class: {
      'pt-5': _vm.fullscreen,
      'py-0': _vm.noPy
    }
  }, [_vm._t("message")], 2), _c('v-card-actions', [_vm._t("actions")], 2), !_vm.hideClose ? _c('v-btn', {
    staticStyle: {
      "z-index": "1"
    },
    attrs: {
      "disabled": _vm.loading,
      "icon": "",
      "absolute": "",
      "top": "",
      "right": ""
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false, _vm.$emit('close');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1) : _vm._e()], 1) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }